import React from "react"

export default function index() {
  return (
    <svg viewBox="0 0 718.071 691.063" className="fill-current text-current">
      <g transform="translate(-547.076 -2012.687)">
        <path d="M1265.147,2017.4v686.352H853.418V2612.7l274.781-1.177-.578-594.121Z" />
        <path d="M870.246,2045.028l-14.9-32.342-14.461,31.925-293.81,654.424H693.568l44.273-97.914,2.127-5.123H864.414L802.094,2459.1l.217-.218h-.6l54.076-120.049,53.637,120.049h-.236l109.326,240.148h145.957Z" />
      </g>
    </svg>
  )
}
