import React from 'react';

// export default function index({ children, type, color }) {
// 	return type === 'primary' ? (
// 		<button className="text-xl xxl:text-2xl bg-black px-16 py-4 text-white uppercase tracking-widest">
// 			{children}
// 		</button>
// 	) : color === 'white' ? (
// 		<button className="text-xl xxl:text-2xl bg-white px-16 py-4 text-black uppercase tracking-widest">
// 			{children}
// 		</button>
// 	) : type === 'secondary' ? (
// 		<button
// 			className="text-xl xxl:text-2xl font-semibold px-8 py-4 uppercase tracking-widest border-current border-t border-r border-b border-l"
// 			style={{ borderColor: 'currentColor' }}
// 		>
// 			{children}
// 		</button>
// 	) : (
// 		<button className="text-xl xxl:text-2xl py-4 uppercase tracking-widest relative">
// 			<span>{children}</span>
// 			<span className="absolute bg-black w-10 h-1 right-0 bottom-0 transform translate-x-full"></span>
// 		</button>
// 	);
// }

export function Primary({ children, color }) {
	const isWhite =
		color === 'white' ? 'bg-white text-black' : 'bg-black text-white';
	return (
		<button
			className={`${isWhite} text-xl xxl:text-2xl px-16 py-4 uppercase tracking-widest`}
		>
			{children}
		</button>
	);
}

export function Secondary({ children }) {
	return (
		<button
			className="text-xl xxl:text-2xl font-semibold px-8 py-4 uppercase tracking-widest border-current border-t border-r border-b border-l"
			style={{ borderColor: 'currentColor' }}
		>
			{children}
		</button>
	);
}

export function Tertiary({ children }) {
	const [isHovering, setIsHovering] = React.useState(false);
	const arrowState = isHovering ? `bottom-1/2 translate-y-full` : `bottom-0 -translate-y-4`;
	return (
		<button
			className="text-xl xxl:text-2xl pb-4 pt-6 uppercase tracking-widest relative font-title"
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<span>{children}</span>
			<span
				className={`${arrowState} absolute bg-black w-10 h-1 right-0 transform translate-x-12 transition-all duration-200 ease-out`}
			>
				<span className={`absolute w-4 h-1 bg-black transition-all transform duration-200 ease-out origin-right ${isHovering ? "rotate-45 translate-x-2 opacity-100" : "opacity-0"}`}></span>
				<span className={`absolute w-4 h-1 bg-black transition-all transform duration-200 ease-out origin-right ${isHovering ? "-rotate-45 translate-x-2 opacity-100" : "opacity-0"}`}></span>
			</span>
		</button>
	);
}
