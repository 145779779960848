import React, { Component } from 'react';

import * as Button from '../Button/';
import Logo from '../Logo/';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import styles from './index.module.scss';

export default class index extends Component {
	constructor(props) {
		super(props);
		this.logo = null;
		this.tl = null;
		this.nav = null;
		this.navItem1 = null;
		this.navItem2 = null;
		this.navItem3 = null;
		this.hiddenNav = null;
		this.navTL = null;
		this.state = {
			menuIsOpened: false,
			isBlack: false,
		};
	}

	componentDidMount() {
		gsap.registerPlugin(ScrollToPlugin);

		this.tl = gsap
			.timeline()
			.to(this.nav, { autoAlpha: 1, duration: 1.5 })
			.delay(2.5);

		this.navTL = gsap
			.timeline()
			.pause()
			.to(this.hiddenNav, { autoAlpha: 1, duration: 1 })
			.fromTo(
				this.navItem1,
				{ autoAlpha: 0, y: 20 },
				{ autoAlpha: 1, y: 0, duration: 0.5 },
				'-=0.6'
			)
			.fromTo(
				this.navItem2,
				{ autoAlpha: 0, y: 20 },
				{ autoAlpha: 1, y: 0, duration: 0.5 },
				'-=0.4'
			)
			.fromTo(
				this.navItem3,
				{ autoAlpha: 0, y: 20 },
				{ autoAlpha: 1, y: 0, duration: 0.5 },
				'-=0.4'
			);

		if (typeof window !== 'undefined') {
			const body = document.body;
			const scrollDown = styles.scrollingDown;
			let lastScroll = 0;
			let changeStateRequest = 0;
			const sensitivity = 2;

			window.addEventListener('scroll', () => {
				const currentScroll = window.pageYOffset;
				this.setState({ menuIsOpened: false });
				if (
					currentScroll > lastScroll &&
					!this.nav.classList.contains(scrollDown) &&
					currentScroll > 0
				) {
					if (changeStateRequest > sensitivity) {
						body.classList.add(scrollDown);
						this.nav.classList.add(scrollDown);
						changeStateRequest = 0;
					} else {
						changeStateRequest++;
					}
				} else if (
					currentScroll < lastScroll &&
					this.nav.classList.contains(scrollDown)
				) {
					if (changeStateRequest > sensitivity) {
						body.classList.remove(scrollDown);
						this.nav.classList.remove(scrollDown);
						changeStateRequest = 0;
					} else {
						changeStateRequest++;
					}
				} else if (currentScroll >= window.innerHeight) {
					this.nav.classList.add(styles.black);
					this.setState({ isBlack: true });
				} else if (currentScroll < window.innerHeight) {
					this.nav.classList.remove(styles.black);
					this.setState({ isBlack: false });
				}

				lastScroll = currentScroll;
			});
		}
	}

	componentDidUpdate() {
		if (this.state.menuIsOpened) {
			this.navTL.play();
			this.nav.classList.remove(styles.black);
		} else {
			this.navTL.timeScale(2).reverse();
			if (this.state.isBlack) {
				this.nav.classList.add(styles.black);
			}
		}
	}

	handleClick = () => {
		if (this.state.menuIsOpened) {
			this.setState({ menuIsOpened: false });
		} else {
			this.setState({ menuIsOpened: true });
		}
	};

	goToClients = () => {
		this.setState({ menuIsOpened: false });
		if (typeof window !== 'undefined') {
			gsap.to(window, {
				scrollTo: '#clients',
				duration: 1,
			}).delay(0.7);
		}
	};
	goToWork = () => {
		this.setState({ menuIsOpened: false });
		if (typeof window !== 'undefined') {
			gsap.to(window, {
				scrollTo: '#work',
				duration: 1,
			}).delay(0.7);
		}
	};
	goToAbout = () => {
		this.setState({ menuIsOpened: false });
		if (typeof window !== 'undefined') {
			gsap.to(window, {
				scrollTo: '#about',
				duration: 1,
			}).delay(0.7);
		}
	};
	render() {
		const { menuIsOpened } = this.state;
		console.log(menuIsOpened);
		return (
			<div
				className={`fixed w-full top-0 z-10 text-white opacity-0 ${styles.navContainer}`}
				ref={(nav) => (this.nav = nav)}
			>
				<nav className="flex items-center justify-between flex-wrap py-10 px-16 relative">
					<div className="flex items-center flex-shrink-0 mr-6 z-50">
						<div
							className="w-16 text-current fixed"
							style={{ top: '2.68rem', left: '4rem' }}
							ref={(logo) => (this.logo = logo)}
						>
							<Logo />
						</div>
					</div>
					<div className="flex md:hidden">
						<div className="text-sm lg:flex-grow flex self-center">
							<button
								className="block text-2xl lg:inline-block lg:mt-0 mr-8"
								onClick={this.goToClients}
							>
								Clients
							</button>
							<button
								className="block text-2xl lg:inline-block lg:mt-0 mr-8"
								onClick={this.goToWork}
							>
								Work
							</button>
							<button
								className="block text-2xl lg:inline-block lg:mt-0"
								onClick={this.goToAbout}
							>
								About
							</button>
						</div>
						<div
							className="border-current border-l ml-12 pl-12"
							style={{ borderColor: 'currentColor' }}
						>
							<a href="mailto:bruce@astronlab.io">
								<Button.Secondary>Contact Us</Button.Secondary>
							</a>
						</div>
					</div>
					<div
						className={`w-16 z-20 absolute right-0 pr-16 hidden md:block ${
							menuIsOpened ? styles.menuIsOpened : ''
						}`}
						style={{ top: '3.5rem' }}
					>
						<div className={styles.hamburger} onClick={this.handleClick}>
							<span className={styles.hamburgerLine}></span>
							<span className={styles.hamburgerLine}></span>
							<span className={styles.hamburgerLine}></span>
						</div>
					</div>
					<div
						className={`hidden md:flex fixed w-full top-0 left-0 text-white justify-start ${styles.hiddenNav} opacity-0 invisible`}
						ref={(hiddenNav) => (this.hiddenNav = hiddenNav)}
					>
						<div className="text-sm flex flex-col text-left justify-start p-20 pt-64 pl-16">
							<button
								className="inline-block text-5xl leading-none mb-10 text-left"
								onClick={this.goToClients}
								ref={(navItem) => (this.navItem1 = navItem)}
							>
								Clients
							</button>
							<button
								className="inline-block text-5xl leading-none mb-10 text-left"
								onClick={this.goToWork}
								ref={(navItem) => (this.navItem2 = navItem)}
							>
								Work
							</button>
							<button
								className="inline-block text-5xl leading-none text-left"
								onClick={this.goToAbout}
								ref={(navItem) => (this.navItem3 = navItem)}
							>
								About
							</button>
						</div>
					</div>
				</nav>
			</div>
		);
	}
}
