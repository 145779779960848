import React from 'react';

export default function Footer() {
	return (
		<footer className="flex py-32 px-40 pb-16 flex-wrap bg-brand-gray lg:px-20 md:px-10">
			<div className="w-2/3 mr-16 lg:w-full lg:mb-24">
				<div className="font-semibold uppercase captions text-xl tracking-widest mb-8">
					Astronlab
				</div>
				<p className="body">
					Global design and development team that build custom softwares.
				</p>
			</div>
			<div className="flex-1">
				<h5 className="captions uppercase font-bold font-title mb-10">
					Find Us
				</h5>
				<p className="body mb-8">
					22/f, On Hong Commercial Building, Hennessy Rd, Wan Chai
				</p>
				<iframe
					className="w-full h-64"
					style={{ filter: 'grayscale(100%)' }}
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1845.9984993736991!2d114.17019465833205!3d22.2781035283293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404005c7da8701b%3A0x4430921f18e163ec!2z5a6J5bq35ZWG5qWt5aSn5buI!5e0!3m2!1sen!2shk!4v1588435493551!5m2!1sen!2shk"
				></iframe>
			</div>
			<div className="w-full mt-32">
				© AstronLab {new Date().getFullYear()}, All rights reserved.
			</div>
		</footer>
	);
}
